const Config = {
  datatableFeatures: {
    extraButtons: [
      {
        buttonLabel: 'Post Referral',
        buttonRef: 'postReferralButton',
        variant: 'info',
      },
    ],
    tableStatusFilterButton: [
      {
        buttonLabel: 'Pending Approval',
        buttonRef: 'inactiveFilter',
        active: true,
      },
      {
        buttonLabel: 'Approved',
        buttonRef: 'activeFilter',
        active: false,
      },
    ],
    buttons: {
      refresh: true,
    },
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'name', text: 'Name' },
    // { value: 'merchant_id', text: 'Merchant ID' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Commission',
        view: 'View Commission',
        remove: 'Remove Commission',
        create: 'Create New Commission',
      },
      form: [],
    }
  },
  tableData() {
    return {
      tableAction: [

        {
          enable: true, param: 'id', btnLabel: 'Assign', action: 'custom', btnIcon: '', btnOnly: false, variant: 'primary', size: 'sm', iconSize: '12', actionParam: 'assign', disableCondition: 'commission_exist:true', disableType: 'boolean',
        },
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'custom', btnIcon: 'SearchIcon', btnOnly: true, variant: 'secondary', size: 'sm', iconSize: '12', actionParam: 'viewReferral', disableCondition: 'status:TEST',
        },
        {
          enable: false, param: 'report_id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12', pageRoute: 'administration-commissions-datatable-details',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
          // pageRoute: 'administration-plans-form',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        // {
        // 'id',
        // 'affiliate_id',
        // 'affiliate_merchant_account_id',
        // 'payout_invoice_id',
        // 'transaction_amount',
        // 'is_paid',
        // 'uuid',
        // 'is_added_to_total',
        // 'transaction_date',
        // 'created_at',
        // }
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        //
        {
          key: 'id', label: 'Id', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'referral_merchant_account_name', label: 'Name', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'affiliate_merchant_account_merchant_id', label: 'Name', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'affiliate_id', label: 'Affiliate Id', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'affiliate_merchant_account_id', label: 'Account ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'payout_invoice_id', label: 'Payout Invoice ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'transaction_amount', label: 'Amount', sortable: true, visible: true, type: 'monetary',
        },
        // {
        //   key: 'nric', label: 'NRIC/Passport', sortable: true, visible: true, type: 'string',
        // },
        {
          key: 'is_paid', label: 'Paid?', sortable: true, visible: false, type: 'boolFlag', showInDetails: true,
        },
        // {
        //   key: 'is_added_to_total', label: 'Added To Total?', sortable: true, visible: true, type: 'boolFlag',
        // },
        {
          key: 'commission_exist', label: 'Commission?', sortable: true, visible: false, type: 'boolFlag',
        },
        {
          key: 'transaction_date', label: 'TXN Date', sortable: true, visible: false, type: 'date', showInDetails: true,
        },
        {
          key: 'uuid', label: 'Affiliate UUID', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        // {
        //   key: 'affiliate_code', label: 'Affiliate Code', sortable: true, visible: true, type: 'string',
        // },
        // {
        //   key: 'affiliate_promo_link', label: 'Link', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'account_bank_name', label: 'Bank', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'payout_service_id', label: 'Payout Service ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'account_number', label: 'Account Number', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'commission_rate', label: 'Commission Rate', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'affiliate_total_amount', label: 'Total Amount', sortable: true, visible: true, type: 'monetary',
        // },
        // {
        //   key: 'id', label: 'Id', sortable: true, visible: true, type: 'string',
        // },
        // {
        //   key: 'created_at', label: 'Created At', sortable: true, visible: false, class: 'text-right', type: 'date',
        // },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, class: 'text-right', type: 'date', showInDetails: true,
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: false, class: 'text-right', type: 'date', showInDetails: true,
        },

        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config

<template>
  <div>
    <b-card>
      <template #header>
        <div class="d-flex justify-content-between">
          <h4 class="mr-1">
            Referral Detail
          </h4>
        </div>
      </template>
      <b-row>
        <b-col
          cols="12"
          lg="4"
          class="mb-lg-2"
        >
          <b-form-group>
            <label class="mb-0">Merchant Name</label>
            <b-form-input
              size="md"
              plaintext
              :value="referralDetail.name"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          class="mb-lg-2"
        >
          <b-form-group>
            <label class="mb-0">Email</label>
            <b-form-input
              size="md"
              plaintext
              :value="referralDetail.email"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="3"
          class="mb-lg-2"
        >
          <b-form-group>
            <label class="mb-0">Merchant ID</label>
            <b-input-group>
              <b-form-input
                size="md"
                disabled
                :value="referralDetail.merchant_id"
              />
              <template #append>
                <b-button
                  size="sm"
                  @click="doCopy(referralDetail.merchant_id, 'Merchant ID')"
                >
                  Copy
                </b-button>
              </template>

            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="3"
          class="mb-lg-2"
        >
          <b-form-group>
            <label class="mb-0">Affiliate UUID</label>
            <b-input-group>
              <b-form-input
                size="md"
                disabled
                :value="referralDetail.affiliate_uuid"
              />
              <template #append>
                <b-button
                  size="sm"
                  @click="doCopy(referralDetail.affiliate_uuid, 'Affiliate UUID')"
                >
                  Copy
                </b-button>
              </template>

            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="3"
          offset-lg="1"
          class="mb-lg-2"
        >
          <b-form-group>
            <label class="mb-0">Affiliate UUID</label>
            <b-input-group>
              <b-form-input
                size="md"
                disabled
                :value="referralDetail.affiliate_code"
              />
              <template #append>
                <b-button
                  size="sm"
                  @click="doCopy(referralDetail.affiliate_code, 'Affiliate Code')"
                >
                  Copy
                </b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="3"
          offset-lg="1"
          class="mb-lg-2"
        >
          <b-form-group>
            <label class="mb-0">Affiliate Promo Link</label>
            <b-input-group>
              <b-form-input
                size="md"
                disabled
                :value="referralDetail.affiliate_promo_link"
              />
              <template #append>
                <b-button
                  size="sm"
                  @click="doCopy(referralDetail.affiliate_promo_link, 'Promo link')"
                >
                  Copy
                </b-button>
              </template>

            </b-input-group>

          </b-form-group>
        </b-col>
      </b-row>

    </b-card>
    <lp-datatable
      ref="ChildReferralRef"
      title="Referrals"
      :extra-parameter="extraParam"
      store-module="affiliate"
      store-data-endpoint="getAffiliateRecordList"
      store-getter-endpoint="getAffiliateRecordList"
      pagination-state-key="affiliateRecordList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="name"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @extraBtn-clicked="extraBtnClicked"
      @actionBtn-clicked="actionBtnClicked"
      @status-filter-btn-clicked="statusFilterBtnClicked"
    />

    <b-modal
      ref="postReferralModal"
      title="Assign Post Referral"
      size="md"
      ok-title="Submit"
      @ok.prevent="submitPostReferrer"
    >
      <template #modal-footer="{ ok, cancel, hide }">

        <b-button
          @click="cancel"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!userChecked"
          @click="ok"
        >
          Submit
        </b-button>
      </template>
      <b-form-group>
        <label>Affiliate Code</label>
        <b-form-input
          v-model="referralDetail.affiliate_code"
          placeholder="Affiliate Code"
          disabled
        />
      </b-form-group>
      <b-form-group
        :class="[{ 'has-error': v$.assignPostReferralForm.email.$error }]"
      >
        <label>Referrer Email</label>
        <b-input-group>
          <b-form-input
            v-model="assignPostReferralForm.email"
            placeholder="Referrer Email"
            size="md"
          />
          <template #append>
            <b-button
              size="sm"
              @click="checkReferrerData"
            >
              Check Email
            </b-button>
          </template>
        </b-input-group>
        <template v-for="(error, idx) in v$.assignPostReferralForm.email.$errors">
          <p
            :key="'error-'+idx"
            class="small text-danger"
          >
            {{ error.$message }}
          </p>
        </template>

        <div
          v-if="fetchedReferrerDetail"
          class="p-1 pt-2"
        >
          <h5>Referrer Info</h5>

          <b-form-group>
            <label>Name</label>
            <b-form-input
              v-model="fetchedReferrerDetail.name"
              placeholder="Affiliate Code"
              disabled
            />
          </b-form-group>

          <b-form-group>
            <label>Account Name</label>
            <b-form-input
              v-model="fetchedReferrerDetail.account_name"
              placeholder="Affiliate Code"
              disabled
            />
          </b-form-group>

          <b-form-group>
            <label>Merchant ID</label>
            <b-form-input
              v-model="fetchedReferrerDetail.merchant_id"
              placeholder="Affiliate Code"
              disabled
            />
          </b-form-group>
        </div>
      </b-form-group>
    </b-modal>
  </div>

</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import humaniseMixin from '@/common/humanise.mixin'
import Config from '@/views/administration/commissionReferrals/detailFormConfig'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'

export default {
  components: { LpDatatable },
  mixins: [humaniseMixin],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      assignPostReferralForm: {
        email: { required, email },
      },
    }
  },
  data() {
    return {
      fetchedReferrerDetail: null,
      account_id: null,
      referralDetail: {
        name: '',
        account_id: '',
        merchant_id: '',
        email: '',
        affiliate_uuid: '',
        affiliate_code: '',
        affiliate_promo_link: '',
      },

      userChecked: false,
      assignPostReferralForm: {
        email: 'nizam.yaakop@gmail.com',
      },
      extraParam: {
        affiliate_id: this.$route.params.id,
        record_status: 2,
      },
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    tableConfig() {
      if (Config) {
        return Config.tableData()
      }
      return {}
    },
  },
  async mounted() {
    // this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Commission Referrals' },
      { title: 'Detail' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    if (this.$route.params.id) {
      const payload = {
        _id: this.$route.params.id,
      }
      await this.$store.dispatch('commissions/getCommissionReferralReferreeList', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.referralDetail = res.data.data
          this.account_id = res.data.data.account_id
        } else {
          this.$router.push({ name: 'administration-commission-referral' })
        }
      })
    } else {
      await this.$router.push({ name: 'administration-commission-referral' })
    }
  },
  methods: {
    doCopy(elem, field = 'Text') {
      this.$copyText(elem).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${field} copied`,
            icon: 'BellIcon',
          },
        })
      }, e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
    async checkReferrerData() {
      const valid = await this.v$.assignPostReferralForm.$validate()
      if (!valid) {
        return
      }

      const payload = {
        email: this.assignPostReferralForm.email,
      }
      this.$store.dispatch('affiliate/getAffiliateDetailsByEmail', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.fetchedReferrerDetail = res.data.data[0]
          this.userChecked = true
        }
      })
    },
    async submitPostReferrer() {
      const valid = await this.v$.assignPostReferralForm.$validate()
      if (!valid) {
        return
      }

      const payload = {
        _code: this.referralDetail.affiliate_code,
        _email: this.assignPostReferralForm.email,
      }

      this.$store.dispatch('affiliate/assignPostReferral', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.$swal.fire({
            title: 'Successfully Assigned',
            text: 'Merchant has been successfully assigned!',
            icon: 'success',
          })
          this.$refs.postReferralModal.hide()
        }

        this.$refs.ChildReferralRef.initDataGrid()
      })
    },
    async extraBtnClicked(e) {
      if (e === 'postReferralButton') {
        this.$refs.postReferralModal.show()
      }
    },
    async actionBtnClicked(e) {
      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: 'Are you sure?',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          const payload = {
            account_id: this.account_id,
            affiliate_merchant_account_id: e.rowData.affiliate_merchant_account_id,
          }

          this.$store.dispatch('commissions/assignReferralToCommission', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$swal.fire({
                title: 'Successfully Assigned',
                text: 'Merchant has been successfully assigned!',
                icon: 'success',
              })
            }

            this.$refs.ChildReferralRef.initDataGrid()
          })
        }
      })
    },
    async statusFilterBtnClicked(e) {
      if (e === 'activeFilter') {
        this.extraParam.record_status = 1
        this.$refs.ChildReferralRef.initDataGrid()
      }
      if (e === 'inactiveFilter') {
        this.extraParam.record_status = 2
        this.$refs.ChildReferralRef.initDataGrid()
      }
    },
  },
}
</script>
